import React from 'react';
import { navigate } from 'gatsby';

const isBrowser = typeof window !== 'undefined';

function GuestPage() {
  if (!isBrowser) {
    return <div />;
  }
  return navigate('/guest/booking/');
}

export default GuestPage;
